import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { 
    Box
} from '@chakra-ui/react'

import HomeVideo from '../../videos/recruiting-video.mp4';
import FallbackImage from '../../images/video-poster-image.png';

const VideoHero = () => {


    return (
        <Box className='background-video-container' position="relative">       
       <video controls autoplay="false" loop="true" muted="false" className='homeVideo' poster={FallbackImage} style={{marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
        <source src={HomeVideo} type="video/mp4" />
        {/* <source src={HomeVideo} type="video/webm" /> */}
      </video>
   
    </Box>
    )
}

export default VideoHero;