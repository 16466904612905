import React, { useState, useRef } from "react"
import {
  Box,
  Grid,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react"

import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot
} from 'react-netlify-forms'

import styled from '@emotion/styled'

import { useForm, useController } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  attachment: yup.mixed()
  .test('required', 'Please attach your resume.', (value) => {
      return value && value.length;
  })
  .test('fileSize', 'Max file size 5 MB', (value, context) => {
      return value && value[0] && value[0].size <= 5000000;
  }),
});

const InternApplication = () => {
    const [state, setState] = React.useState({})
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
      resolver: yupResolver(schema)
  })


  const netlify = useNetlifyForm({
    name: 'Intern Application',
    onSuccess: (response, context) => {
      console.log('Data successfully sent.')
    }
  });

  const onSubmit = (data) => netlify.handleSubmit(null, data);

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const watchData = watch();

  
  const ContactGrid = styled(Grid)`
    grid-template-columns: 1fr;

    @media(min-width: 48em) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
    }
    
  `;

  const SuccessMessage = () => (
      <Box my="12">      
          <Heading as="h4" w="100%">Thank you for your submission.<br />
          We'll be in touch soon!</Heading>
      </Box>
  )


  return (
    <>
      <Box  px={4} mb={8}>
              <>
        {/* <Heading as="h3" fontSize="xl" color="steel.700">Send us a message:</Heading> */}
        {/* <form method="POST" name="wholesale-application-form" data-netlify="true"> */}
        {/* <form onSubmit={(event) => handleSubmit(onSubmit(event))} name="intern-application-form" data-netlify="true" id="internForm"> */}
        {/* <input type="hidden" name="form-name" value="intern-application-form" /> */}
        <NetlifyFormProvider {...netlify}>
          <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
          <ContactGrid className="contactGrid"  py={4}>
            <Box w="100%">
              <FormControl id="email">
                <FormLabel color="shadow.800">Your Name<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="text"
                    {...register("name", {required: true} )}
                />
                {errors.name && <Text color="red.200">Please enter your name.</Text>}
              </FormControl>
            </Box>
          </ContactGrid>
          <Button variant="gold-button" type="submit" mt={4} >
              {processing ? "Submitting..." : "Submit" }
          </Button>
        </NetlifyFormComponent>
        </NetlifyFormProvider>
        </>
      </Box>
    </>
  )
}

export default InternApplication
