import React from 'react'
import {
    Box,
    Slide,
    Button,
    Collapse,
    useDisclosure
} from '@chakra-ui/react'
import WholesaleApplicationForm from './WholesaleApplicationForm'

const WholesaleButton = () => {
    const { isOpen, onToggle } = useDisclosure()
    return (
        <>
        <Button onClick={onToggle} variant="gold-button" whiteSpace="normal" mt={{base: '2'}} p="2"  w="100%">Apply Now To Be A Wholesale Lumber Broker</Button>
        <Collapse in={isOpen} animateOpacity>
          <WholesaleApplicationForm />
        </Collapse>
      </>
    )
}

export default WholesaleButton
