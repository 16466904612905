import React from 'react'
import {
    Button,
    Collapse,
    useDisclosure
} from '@chakra-ui/react'
import InternApplicationForm from './InternApplicationForm'
import InternApplicationFormNetlify from './InternApplicationFormNetlify'

const WholesaleButton = () => {
    const { isOpen, onToggle } = useDisclosure()
    return (
        <>
        <Button onClick={onToggle} variant="gold-button" whiteSpace="normal" mt={{base: '2'}} p="2" w="100%">Apply Now For A Trading Internship</Button>
        <Collapse in={isOpen} animateOpacity>
          <InternApplicationForm />
          {/* <InternApplicationFormNetlify /> */}
        </Collapse>
      </>
    )
}

export default WholesaleButton
